import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const ReactSelect = ({ name, control, defaultValue, rules, placeholder, options, onChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      onChange={onChange}
      render={({ field }) => (
        <Select
          {...field}
          inputId={name}
          placeholder={placeholder}
          onChange={(selectedOption) => {
            field.onChange(selectedOption);
            onChange(selectedOption);
          }}
          options={options}
          classNamePrefix="react-select"
          className="select-container"
        />
      )}
    />
  );
}

export default ReactSelect;
