import React, { useState } from 'react';
import Question from './question.jpg';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';


const ProductItem = ({ item, deleteProduct }) => {
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);

  const handleDelete = async () => {
    try {
      deleteProduct(item.id);
      // const productRef = collection(db, 'products');
      // const productListRef = doc(productRef, 'user-products');
      // const productListDoc = await getDoc(productListRef);
      // const productListData = productListDoc.data();

      // const indexToDelete = productListData.dataProducts.findIndex(product => product.id === item.id);

      // if (indexToDelete !== -1) {
      //   productListData.dataProducts.splice(indexToDelete, 1);

      //   await setDoc(productListRef, productListData);
      // }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
  };

  return (
    <div className="product-item">
      <div className="product-id">
        <label className='mobile-label'>Product ID</label>
        <span>#{item.id}</span>
      </div>
      <div className="product-name">
        <div className="product-thumb">
          <img src={item.img ? item.img : Question} alt="" />
        </div>
        <span>{item.name}</span>
      </div>
      <div className="product-category">
        <label className='mobile-label'>Category</label>
        <span>{item.category}</span>
      </div>
      <div className="product-status">
        <label className='mobile-label'>Status</label>
        <span>{item.status}</span>
      </div>
      <div className="product-price">
        <label className='mobile-label'>Price</label>
        <span>${item.price}</span>
      </div>
      <div className="product-actions">
        <button className="btn-icon product-detailed" type="button"></button>
        <button className="btn-icon product-delete" type="button" onClick={openAddPopup}></button>
      </div>
      <div className={`popup-confirmation ${isAddPopupOpen ? 'open' : ''}`}>
        <div className='popup-confirmation__container'>
          <p className='popup-confirmation__title'>Are you sure you want to delete the product?</p>
          <div className='popup-confirmation__action'>
            <button className="popup-confirmation__btn confirm" type="button" onClick={handleDelete}>Yes</button>
            <button className="popup-confirmation__btn cancel" type="button" onClick={closeAddPopup}>No</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItem;
