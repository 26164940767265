import React from 'react';
import { Link } from "react-router-dom";
import NavMenu from '../NavMenu/NavMenu';

const Sidebar = ({isMinimize, setMinimize, isOpenMenu, setOpenMenu}) => {
  const minimizeSideBar = (isMinimize) => {
    if (!isMinimize) {
      setMinimize(true);
    } else {
      setMinimize(false);
    }
  }

  return (
    <div className={`sidebar ${isOpenMenu ? 'open' : ''}`}>

      <div className="logo">
        <Link to="/">
          <div className="logo__icon">
            <img src="/images/logo-icon.svg" alt="logo" />
          </div>
          <div className="logo__text">
            <img src="/images/logo-text.svg" alt="Swiftdex" />
          </div>
        </Link>
      </div>

      <button onClick={() => {setOpenMenu(false)}} className="btn-icon close-menu" type="button"></button>

      <NavMenu/>

      <button onClick={() => {minimizeSideBar(isMinimize)}} className="nav__item sidebar__control">Minimize</button>
    </div>
  )
}

export default Sidebar;
