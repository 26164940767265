import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import OrdersPage from "./pages/OrdersPage";
import ProductsPage from "./pages/ProductsPage";
import SettingsPage from "./pages/SettingsPage";
import TeamPage from "./pages/TeamPage";
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AuthSideBar from './components/AuthSideBar';
import ForgotPassword from './pages/ForgotPassword';

const App = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isMinimize, setMinimize] = useState(false);
  const location = useLocation();

  const [userCurrent, setUserCurrent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserCurrent(user);
      } else {
        setUserCurrent(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  if (loading) {
    return <Loading type='preload'/>;
  }

  return (
    <div className="App">
      <div className={userCurrent ? `page ${isMinimize ? 'sidebar-minimize' : ''}` : 'form-content'}>
        <div className="page__inner">
        {userCurrent
          ? <Sidebar isMinimize={isMinimize} setMinimize={setMinimize} isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
          : <AuthSideBar/>}
          <div className="page__content">
            <div>
              {userCurrent && <Header
                  isOpenMenu={isOpenMenu}
                  setOpenMenu={setOpenMenu}
                  user={userCurrent}
                />
              }
              <Routes>
                <Route path="/"
                  element={userCurrent
                    ? <HomePage titlePage={'Welcome back, Stephen!'} isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
                    : <Navigate to="/sign-in"/>
                  }
                />
                <Route exact path="/sign-in"
                  element={!userCurrent
                    ? <SignIn title="Welcome back!" description="Sign In to your Swiftdex account"/>
                    : <Navigate to="/" />
                  }
                />
                <Route exact path="/sign-up"
                  element={!userCurrent
                    ? <SignUp title="Create an account" description="Join us on board"/>
                    : <Navigate to="/sign-in" />
                  }
                />
                <Route exact path="/forgot-password"
                  element={!userCurrent
                    ? <ForgotPassword 
                        title="Recover your password"
                        description="Enter the email that you used when register. You will receive a password reset link."/>
                    : <Navigate to="/sign-in" />
                  }
                />
                <Route path="/orders"
                  element={userCurrent
                    ? <OrdersPage titlePage={'Orders'}/>
                    : <Navigate to="/sign-in"/>
                  }
                />
                <Route path="/products"
                  element={userCurrent
                    ? <ProductsPage titlePage={'Products'} />
                    : <Navigate to="/sign-in"/>
                  }
                />
                <Route path="/settings"
                  element={userCurrent
                    ? <SettingsPage titlePage={'Settings'} user={userCurrent}/>
                    : <Navigate to="/sign-in"/>
                  }
                />
                <Route path="/team"
                  element={userCurrent
                    ? <TeamPage titlePage={'Team'} currentUser={userCurrent}/>
                    : <Navigate to="/sign-in"/>
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App
