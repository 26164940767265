
import {configureStore} from '@reduxjs/toolkit';
import userReducer from './slices/user';
import productReducer from './slices/product';
import teamReducer from './slices/team';
import chatReducer from './slices/chat';

const store = configureStore({
    reducer: {
        user: userReducer,
        product: productReducer,
        team: teamReducer,
        chat: chatReducer,
    }
});

export default store;