import React from 'react';

const SignHeader = ({title, description}) => {
  return (
    <div className='sign-header'>
      <h2>
        {title}
      </h2>
      <span className='description'>
        {description}
      </span>
    </div>
  )
}

export default SignHeader;
