import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../../firebase';
import { updateProfile, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {setUser} from '../../../store/slices/user';
import { useForm } from "react-hook-form";

const SignUpForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordConfirmVisibility = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const password = useRef();
  password.current = watch('password', '');

  const handleSignUp = async (data) => {
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((result) => {
        const fullName = `${data.fullName}`;

        updateProfile(auth.currentUser, {
          displayName: fullName,
        })
        .then(() => {
          dispatch(setUser({
            email: result.user.email,
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
          }));

          navigate("/sign-in");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  }

  return (
    <>
      <form className='sign'>
        <div className='sign_container'>
          <div className={`sign_item ${errors.fullName ? "error" : ''}`}>
            <label>Full Name</label>
            <input
              name="fullName"
              type="text"
              {...register("fullName", {
                required: "Full name is required.",
                pattern: {
                  value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                  message: "First name is not valid."
                }
              })}
            />
            {errors.fullName &&
              <span className='error-message'>Full Name is required</span>
            }
          </div>
          <div className={`sign_item ${errors.email ? "error" : ''}`}>
            <label>Email address</label>
            <input
              name="email"
              type="text"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid."
                }
              })}
            />
            {errors.email &&
              <span className='error-message'>{errors.email.message}</span>
            }
          </div>
          <div className={`sign_item ${errors.password ? "error" : ''}`}>
            <label>Password</label>
            <div className='sign_item-input'>
              <input
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                {...register("password", {
                  required: "Password is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9]{6,12}$/,
                    message: "Password must contain only uppercase and lowercase letters and numbers"
                  },
                  minLength: {
                    value: 6,
                    message: "Password should be at-least 6 characters."
                  },
                  maxLength: {
                    value: 12,
                    message: "Password must be less than 12 characters"
                  },
                })}
              />
              <button
                className={`check-password ${passwordVisible ? 'show' : 'hide'}`}
                type="button"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.password && (
              <span className='error-message'>{errors.password.message}</span>
            )}
          </div>
          <div className={`sign_item ${errors.confirmPassword ? "error" : ''}`}>
            <label>Confirm Password</label>
            <div className='sign_item-input'>
              <input
                name="confirmPassword"
                type={passwordConfirmVisible ? 'text' : 'password'}
                {...register("confirmPassword", {
                  required: "Confirm password is required.",
                  validate: (value) => value === password.current || 'Passwords do not match'
                })}
              />
              <button
                className={`check-password ${passwordConfirmVisible ? 'show' : 'hide'}`}
                type="button"
                onClick={togglePasswordConfirmVisibility}
              />
            </div>
            {errors.confirmPassword &&
              <span className='error-message'>{errors.confirmPassword.message}</span>
            }
          </div>
          <div className={`sign_checkbox ${errors.agree ? "error" : ''}`}>
              <label className="custom-checkbox">
                <input
                  className="custom-checkbox_input"
                  type="checkbox"
                  name="agree"
                  {...register("agree", {
                    required: 'Please accept the terms and conditions'
                  })}
                />
                <span className="custom-checkbox_icon">
                  <span>I agree with <Link to="#">Terms and Conditions</Link></span>
                </span>
              </label>
              {errors.agree &&
                <span className='error-message'>{errors.agree.message}</span>
              }
          </div>
          <div className='sign_action'>
            <button
              className='button button-primary'
              type="submit"
              onClick={handleSubmit(handleSignUp)}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
      <div className='sign-form_action'>
        <span className='sign-form_action-text'>
          Already have an account?  <Link to="/sign-in" className='sign-form_action-link'>Sign In</Link>
        </span>
      </div>
    </>
  )
}

export default SignUpForm;
