import React from 'react';

const Search = ({ name, placeholder, onSearch, value }) => {

  return (
    <form className='search-bar'>
      <div className='search-bar__content'>
        <input
          name={name}
          type='text'
          placeholder={placeholder}
          className='search-bar__input'
          value={value}
          onChange={onSearch}
        />
      </div>
    </form>
  );
};

export default Search;