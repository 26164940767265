import React, { useState, useEffect } from 'react';
import ProductItem from "../components/ProductItem";
import Search from '../components/Search/Search';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import ReactPaginate from 'react-paginate';
import Loader from '../components/Loader/Loader';
import AddPopup from '../components/AddPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  loadSuccess,
  loadFailure,
  addProduct as addProductToRedux,
  removeProduct as removeProductFromRedux,
} from '../store/slices/product';

const ProductsPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const perPage = 10;
  const [searchText, setSearchText] = useState('');
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.data);
  const loading = useSelector((state) => state.product.loading);
  const error = useSelector((state) => state.product.error);

  useEffect(() => {
    const fetchProductsFromFirebase = async () => {
      try {
        dispatch(startLoading());

        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = [];

        querySnapshot.forEach((doc) => {
          const dataProducts = doc.data().dataProducts;
          dataProducts.forEach((product) => {
            productsData.push(product);
          });
        });

        if (productsData.length > 0) {
          dispatch(loadSuccess(productsData));
        } else {
          dispatch(loadFailure("No data found."));
        }
      } catch (error) {
        dispatch(loadFailure(error.message));
      } finally {
        setLoader(false);
      }
      console.log('fetchProductsFromFirebase was called');
    };

    if (!products || products.length === 0) {
      fetchProductsFromFirebase();
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (products && products.length > 0) {
      const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

      const filteredData = products.filter((product) => {
        const { name } = product;
        return name && name.toLowerCase().includes(lowerCaseSearchText);
      });

      setTotalItems(filteredData.length);
    }
  }, [products, searchText]);

  // useEffect(() => {
  //   const fetchDataFromFirestore = async () => {
  //     const querySnapshot = await getDocs(collection(db, 'products'));
  //     const data = [];

  //     querySnapshot.forEach((doc) => {
  //       data.push(doc.data());
  //     });

  //     setData(data);

  //     setLoading(false);
  //   };

  //   fetchDataFromFirestore();
  // }, []);

  // useEffect(() => {
  //   const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

  //   const filteredData = data.flatMap((entry) => {
  //     return entry.dataProducts.filter((product) => {
  //       const { name } = product;

  //       return (
  //         (name && name.toLowerCase().includes(lowerCaseSearchText))
  //       );
  //     });
  //   });

  //   setTotalItems(filteredData.length);
  // }, [data, searchText]);

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
  };

  const handleSearch = (eventOrText) => {
    const searchText = eventOrText.target ? eventOrText.target.value : eventOrText;
    setSearchText(searchText);
    setCurrentPage(0);
  };

  // const getCurrentData = () => {
  //   const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

  //   const filteredData = data.flatMap((entry) => {
  //     return entry.dataProducts.filter((product) => {
  //       const { name } = product;

  //       return (
  //         (name && name.toLowerCase().includes(lowerCaseSearchText))
  //       );
  //     });
  //   });

  //   return filteredData;
  // };

  const getCurrentData = () => {
    if (products && products.length > 0) {
      const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

      const filteredData = products.filter((product) => {
        const { name } = product;
        return name && name.toLowerCase().includes(lowerCaseSearchText);
      });

    return filteredData;
    } else {
      return [];
    }
  };

  // const handleDelete = async (productId) => {
  //   try {
  //     const updatedData = data.map(entry => {
  //       return {
  //         ...entry,
  //         dataProducts: entry.dataProducts.filter(product => product.id !== productId)
  //       };
  //     });

  //     setData(updatedData);
  //   } catch (error) {
  //     console.error('Error deleting product:', error);
  //   }
  // };

  // const handleAddProduct = async (newProductObj) => {
  //   try {
  //     const updatedData = data.map(entry => {
  //       return {
  //         ...entry,
  //         dataProducts: [...entry.dataProducts, newProductObj]
  //       };
  //     });

  //     setData(updatedData);
  //   } catch (error) {
  //     console.error('Error adding product:', error);
  //   }
  // };

  const handleDelete = (productId) => {
    dispatch(removeProductFromRedux(productId));
  };

  const handleAddProduct = (newProductObj) => {
    dispatch(addProductToRedux(newProductObj));
  };

  const filteredData = getCurrentData();
  const pageCount = Math.ceil(filteredData.length / perPage);

  const dataProduct = filteredData.slice(currentPage * perPage, (currentPage + 1) * perPage).map((entry) => {
    return (
      <ProductItem item={entry} key={entry.id} deleteProduct={handleDelete}/>
    );
  });

  const getCurrentRange = () => {
    if (totalItems === 0) {
      return { start: 0, end: 0 };
    }

    const start = currentPage * perPage + 1;
    const end = Math.min((currentPage + 1) * perPage, totalItems);
    return { start, end };
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
  };

  return (
    <>
      <div className="wrapper-products-content">
        <div className="product-page-header">
        <div className='search-team'>
          <Search
            placeholder="Search by products"
            value={searchText}
            onSearch={handleSearch}
          />
          <button className='add-member-btn btn-add-product' onClick={openAddPopup}>Add product</button>
          <AddPopup isOpen={isAddPopupOpen} onClose={closeAddPopup} type='product' onAddProduct={handleAddProduct} />
          <button className="btn-export-product" type="button">Export</button>
        </div>
        </div>
        <div className="product-list-header">
          <div className="product-id">Product ID</div>
          <div className="product-name">Product name</div>
          <div className="product-category">Category</div>
          <div className="product-status">Status</div>
          <div className="product-price">Price</div>
          <div className="product-actions">Actions</div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="product-list">
              {dataProduct.length === 0 ? (
                <div className='team-items__none'>
                  <h2>Not found</h2>
                </div>
              ) : (
                dataProduct
              )}
            </div>
            <div className="product-pager">
              <div className="product-count">
                Showing {getCurrentRange().start}-{getCurrentRange().end} of {totalItems} items
              </div>
              <div className="product-pager-list">
                {pageCount > 1 && (
                  <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    pageClassName='pagination-item'
                    pageLinkClassName='pagination-link'
                    previousClassName='pagination-item previous'
                    previousLinkClassName='pagination-link'
                    nextClassName='pagination-item next'
                    nextLinkClassName='pagination-link'
                    breakClassName='pagination-item break'
                    breakLinkClassName='pagination-link'
                    activeClassName='active'
                    previousLabel='Prev'
                    nextLabel='Next'
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ProductsPage;