import React from 'react';

const Loading = ({type}) => {
  return (
    <>
      {type === 'load' &&
        <div className='loading'>
          <div className='loading__img'></div>
        </div>
      }
      {type === 'preload' &&
        <div className='preload'>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
          <div className="preload__wave"></div>
        </div>
      }
    </>
  )
}

export default Loading;
