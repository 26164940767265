import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signInWithPopup, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/slices/user';

const SignBtn = ({ link, type, social, provider }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const signIn = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);

      await signInWithPopup(auth, provider)
        .then((result) => {
          dispatch(setUser({
            email: result.user.email,
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
          }));
        });

      navigate("/")
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError('Please enter a valid email');
      } else if (error.code === "auth/wrong-password") {
        setError('Please enter the correct password');
      } else if (error.code === "auth/user-not-found") {
        setError('User does not exist');
      } else if (error.code === "auth/too-many-requests") {
        setError('Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
      }
    };
  }

  return (
    <>
      <Link onClick={signIn} to={link} className={`sign-btn ${social}`}>{type}</Link>
      {error &&
        <div className='sign_item'>
          <span className='sign_item-error'>{error}</span>
        </div>
      }
    </>
  )
}

export default SignBtn;
