import React, { useState, useEffect } from 'react';
import SettingsCard from '../components/SettingsCard';
import ToggleSwitch from "react-switch";
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from '../components/ReactSelect/ReactSelect';

const SettingsPage = ({ user }) => {
  const { control: emailControl, handleSubmit: handleSubmitEmailNotifications, watch, setValue } = useForm({
    defaultValues: {
      systemNotifications: true,
      teamChatMessages: false,
      newOrders: true,
      ordersStatusUpdates: false,
      weeklyStatistics: true,
    }
  });
  const { control: preferencesControl, handleSubmit: handleSubmitPreferences} = useForm();
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const [isPreferencesSaveActive, setIsPreferencesSaveActive] = useState(false);
  const [colorSchemeDirty, setColorSchemeDirty] = useState(false);
  const [defaultHomepageDirty, setDefaultHomepageDirty] = useState(false);
  const [dataFormatDirty, setDataFormatDirty] = useState(false);
  const [languageDirty, setLanguageDirty] = useState(false);
  const [timeZoneDirty, setTimeZoneDirty] = useState(false);
  const [notificationStates, setNotificationStates] = useState({
    systemNotifications: false,
    teamChatMessages: false,
    newOrders: false,
    ordersStatusUpdates: false,
    weeklyStatistics: false,
  });

  const colorSchemeOptions = [
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];

  const defaultHomepageOptions = [
    { value: 'dashboard', label: 'Dashboard' },
    { value: 'orders', label: 'Orders' },
    { value: 'products', label: 'Products' },
    { value: 'team', label: 'Team' },
    { value: 'settings', label: 'Settings' },
  ];

  const dataFormatOptions = [
    { value: 'mdy', label: 'M/D/YYYY' },
    { value: 'my', label: 'M/YYYY' },
    { value: 'y', label: 'YYYY' },
  ];

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' },
    { value: 'fr', label: 'French' },
  ];

  const timeZoneOptions = [
    { value: 'est', label: 'Eastern Time - US and Canada' },
    { value: 'ct', label: 'Central Time' },
    { value: 'i', label: 'India Time Zone' },
  ];

  useEffect(() => {
    const hasChanged = Object.values(notificationStates).some(value => value);
    setIsSaveButtonActive(hasChanged);
  }, [notificationStates]);

  const handleChange = (notification) => {
    setNotificationStates({
      ...notificationStates,
      [notification]: !notificationStates[notification],
    });
    setValue(notification, !watch(notification)); 
  };

  const handleColorSchemeChange = (selectedOption) => {
    const currentValue = selectedOption.value;
    if (currentValue !== colorSchemeOptions[0].value) {
      setColorSchemeDirty(true);
    } else {
      setColorSchemeDirty(false);
    }
  };

  const handleDefaultHomepageChange = (selectedOption) => {
    const currentValue = selectedOption.value;
    if (currentValue !== defaultHomepageOptions[0].value) {
      setDefaultHomepageDirty(true);
    } else {
      setDefaultHomepageDirty(false);
    }
  };

  const handleDataFormatChange = (selectedOption) => {
    const currentValue = selectedOption.value;
    if (currentValue !== dataFormatOptions[0].value) {
      setDataFormatDirty(true);
    } else {
      setDataFormatDirty(false);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const currentValue = selectedOption.value;
    if (currentValue !== languageOptions[0].value) {
      setLanguageDirty(true);
    } else {
      setLanguageDirty(false);
    }
  };

  const handleTimeZoneChange = (selectedOption) => {
    const currentValue = selectedOption.value;
    if (currentValue !== timeZoneOptions[0].value) {
      setTimeZoneDirty(true);
    } else {
      setTimeZoneDirty(false);
    }
  };

  const renderToggleSwitch = (field, label) => {
    return (
      <div className={`block-item__item switch ${watch(field) ? 'on' : 'off'}`}>
        <Controller
          name={field}
          control={emailControl}
          defaultValue={watch(field)}
          render={({ field }) => (
            <ToggleSwitch
              onChange={(checked) => {
                handleChange(field.name);
                field.onChange(checked);
              }}
              checked={field.value}
              id={field.name}
              className='toggle-switch'
              handleDiameter={16}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={32}
              onColor='#627EEA'
              offColor='#BCBECA'
            />
          )}
        />
        <label>{label}</label>
      </div>
    );
  };

  useEffect(() => {
    const isDirty =
      colorSchemeDirty ||
      defaultHomepageDirty ||
      dataFormatDirty ||
      languageDirty ||
      timeZoneDirty;

    setIsPreferencesSaveActive(isDirty);
  }, [colorSchemeDirty, defaultHomepageDirty, dataFormatDirty, languageDirty, timeZoneDirty]);

  const onSubmitToggle = (data) => {
    console.log(data);
    setIsSaveButtonActive(false);
  };

  const onSubmitPreferences = (data) => {
    console.log(data);
    setIsPreferencesSaveActive(false);
  };

  return (
    <>
      <div className="wrapper-blocks">
        <div className="data-blocks">
          <div className="blocks-list">
            <div className='block-item full-width block-item__full-width block__settings'>
              <SettingsCard
                title='General'
                description='Public information about your account'
                user={user}
              />
            </div>
            <div className='block-item full-width block-item__full-width block__settings'>
              <SettingsCard
                title='Login info'
                description='The credentials for authorization'
                user={user}
              />
            </div>
          </div>
        </div>

        <div className="block-item currency-wrapper white-bg settings-bar">
          <div className="currency-block__item">
            <div className="block-item__header settings">
              <h3 className="block-item__title">Preferences</h3>
            </div>
            <div className="block-item__content">
              <form className="block-item__form preferences" onSubmit={handleSubmitPreferences(onSubmitPreferences)}>
                <div className='block-item__item'>
                  <label>Color scheme</label>
                  <ReactSelect
                    name="colorScheme"
                    control={preferencesControl}
                    defaultValue={colorSchemeOptions[0]}
                    rules={{ required: 'Color scheme is required.' }}
                    placeholder="Select color scheme"
                    options={colorSchemeOptions}
                    onChange={handleColorSchemeChange}
                  />
                </div>
                <div className='block-item__item'>
                  <label>Default Homepage</label>
                  <ReactSelect
                    name="defaultHomepage"
                    control={preferencesControl}
                    defaultValue={defaultHomepageOptions[0]}
                    rules={{ required: 'Default homepage is required.' }}
                    placeholder="Select default homepage"
                    options={defaultHomepageOptions}
                    onChange={handleDefaultHomepageChange}
                  />
                </div>
                <div className='block-item__item'>
                  <label>Data Format</label>
                  <ReactSelect
                    name="dataFormat"
                    control={preferencesControl}
                    defaultValue={dataFormatOptions[0]}
                    rules={{ required: 'Data format is required.' }}
                    placeholder="Select data format"
                    options={dataFormatOptions}
                    onChange={handleDataFormatChange}
                  />
                </div>
                <div className='block-item__item'>
                  <label>Language</label>
                  <ReactSelect
                    name="language"
                    control={preferencesControl}
                    defaultValue={languageOptions[0]}
                    rules={{ required: 'Language is required.' }}
                    placeholder="Select language"
                    options={languageOptions}
                    onChange={handleLanguageChange}
                  />
                </div>
                <div className='block-item__item'>
                  <label>Time Zone</label>
                  <ReactSelect
                    name="timeZone"
                    control={preferencesControl}
                    defaultValue={timeZoneOptions[0]}
                    rules={{ required: 'Time zone is required.' }}
                    placeholder="Select time zone"
                    options={timeZoneOptions}
                    onChange={handleTimeZoneChange}
                  />
                </div>
                <button className='button button-primary' type="submit" disabled={!isPreferencesSaveActive}>Save</button>
              </form>
            </div>
          </div>
          <div className="currency-block__item">
            <div className="block-item__header settings">
              <h3 className="block-item__title">Email Notifications</h3>
            </div>
            <div className="block-item__content">
              <form className="block-item__form" onSubmit={handleSubmitEmailNotifications(onSubmitToggle)}>
                {renderToggleSwitch('systemNotifications', 'System notifications')}
                {renderToggleSwitch('teamChatMessages', 'Team chat messages')}
                {renderToggleSwitch('newOrders', 'New orders')}
                {renderToggleSwitch('ordersStatusUpdates', 'Orders status updates')}
                {renderToggleSwitch('weeklyStatistics', 'Weekly Statistics')}
                <button className='button button-primary' type="submit" disabled={!isSaveButtonActive}>Save</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsPage;