import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserCurrent from '../UserCurrent/UserCurrent';

const Header = ({ user, titlePage, isOpenMenu, setOpenMenu }) => {
  const location = useLocation();
  const fullName = user ? user.displayName || '' : '';
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return `Welcome back, ${firstName}!`;
      case '/orders':
        return 'Orders';
      case '/products':
        return 'Products';
      case '/team':
        return 'Team';
      case '/settings':
        return 'Settings';
      default:
        break;
    }
  };

  return (
    <header className="page__header">
      <h1 className="page__title">{getTitle()}</h1>
      {location.pathname === '/' &&
        <button className="btn-icon btn-calendar" type="button">Last week</button>
      }
      <div className="user-wrapper">
        <button onClick={() => {setOpenMenu(true)}} className="btn-icon hamb" type="button"></button>
        <Link to='/orders' className="btn-icon btn-notification has-notification" type="button"></Link>
        <Link to='/team' className="btn-icon btn-chat has-message" type="button"></Link>
        <div className="user">
          <UserCurrent user={user}/>
        </div>
      </div>
    </header>
  )
}

export default Header;
