import React, { useState, useEffect } from 'react';
import CardTeam from '../components/CardTeam/CardTeam';
import Search from '../components/Search/Search';
import ReactPaginate from 'react-paginate';
import Chat from '../components/Chat/Chat';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import Loader from '../components/Loader/Loader';
import AddPopup from '../components/AddPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  loadSuccess,
  loadFailure,
  addTeamUser as addTeamUserToRedux,
} from '../store/slices/team';

const TeamPage = ({currentUser}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const perPage = 9;
  const [searchText, setSearchText] = useState('');
  const [isPopupChat, setIsPopupChat] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);

  const dispatch = useDispatch();
  const teams = useSelector((state) => state.team.data);
  const loading = useSelector((state) => state.team.loading);
  const error = useSelector((state) => state.team.error);

  useEffect(() => {
    const fetchProductsFromFirebase = async () => {
      try {
        dispatch(startLoading());
  
        const querySnapshot = await getDocs(collection(db, 'users'));
        const teamsData = [];
  
        querySnapshot.forEach((doc) => {
          const dataProducts = doc.data().userList;
          dataProducts.forEach((team) => {
            teamsData.push(team);
          });
        });
  
        if (teamsData.length > 0) {
          dispatch(loadSuccess(teamsData));
        } else {
          dispatch(loadFailure("No data found."));
        }
      } catch (error) {
        dispatch(loadFailure(error.message));
      } finally {
        setLoader(false);
      }
    };

    if (!teams || teams.length === 0) {
      fetchProductsFromFirebase();
    }
  }, [dispatch, teams]);

  // useEffect(() => {
  //   const fetchDataFromFirestore = async () => {
  //     const querySnapshot = await getDocs(collection(db, 'users'));
  //     const data = [];

  //     querySnapshot.forEach((doc) => {
  //       data.push(doc.data());
  //     });

  //     setData(data);
  //     setTotalItems(data.length);

  //     setLoader(false);
  //   };

  //   fetchDataFromFirestore();
  // }, []);

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
  };

  const handleSearch = (eventOrText) => {
    const searchText = eventOrText.target ? eventOrText.target.value : eventOrText;
    setSearchText(searchText);
    setCurrentPage(0);
  };

  const getCurrentData = () => {
    if (teams && teams.length > 0) {
      const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

      const filteredData = teams.filter((team) => {
        const { name } = team;
        return name && name.toLowerCase().includes(lowerCaseSearchText);
      });

    return filteredData;
    } else {
      return [];
    }
  };

  // const getCurrentData = () => {
  //   const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

  //   const filteredData = data.flatMap((entry) => {
  //     return entry.userList.filter((user) => {
  //       const { name} = user;

  //       return (
  //         (name && name.toLowerCase().includes(lowerCaseSearchText))
  //       );
  //     });
  //   });

  //   return filteredData;
  // };

  // const handleAddMember = async (newMemberObj) => {
  //   try {
  //     const updatedData = data.map(entry => {
  //       return {
  //         ...entry,
  //         userList: [...entry.userList, newMemberObj]
  //       };
  //     });

  //     setData(updatedData);
  //   } catch (error) {
  //     console.error('Error adding product:', error);
  //   }
  // };

  const handleAddMember = (newMemberObj) => {
    dispatch(addTeamUserToRedux(newMemberObj));
  };

  const filteredData = getCurrentData();
  const pageCount = Math.ceil(filteredData.length / perPage);

  const dataTeam = filteredData.slice(currentPage * perPage, (currentPage + 1) * perPage).map((entry, id) => {
    const { img, status, name, position, email } = entry;

    return (
      <div className='team-item' key={id}>
        <CardTeam
          img={img}
          status={status}
          name={name}
          position={position}
          email={email}
          key={id}
        />
      </div>
    );
  });

  const сlickPopupChat = () => {
    setIsPopupChat(!isPopupChat);
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
  };

  return (
    <>
      <div className='wrapper-blocks'>
        <div className='data-blocks'>
          <div className='wrapper-team-content'>
            <div className='search-team'>
              <Search
                placeholder="Search by team"
                value={searchText}
                onSearch={handleSearch}
              />
              <button className='add-member-btn' onClick={openAddPopup}>Add member</button>
              <AddPopup isOpen={isAddPopupOpen} onClose={closeAddPopup} type='member' onAddMember={handleAddMember}/>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className='team-items'>
                  {dataTeam.length === 0 ? (
                    <div className='team-items__none'>
                      <h2>Not found</h2>
                    </div>
                  ) : (
                    dataTeam
                  )}
                </div>
                {pageCount > 1 && (
                  <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    pageClassName='pagination-item'
                    pageLinkClassName='pagination-link'
                    previousClassName='pagination-item previous'
                    previousLinkClassName='pagination-link'
                    nextClassName='pagination-item next'
                    nextLinkClassName='pagination-link'
                    breakClassName='pagination-item break'
                    breakLinkClassName='pagination-link'
                    activeClassName='active'
                    previousLabel='Prev'
                    nextLabel='Next'
                  />
                )}
              </>
              )
            }
          </div>
        </div>
        <div className={`block-item currency-wrapper block-item-chat ${isPopupChat ? 'open' : ''}`}>
          <Chat
            onClick={сlickPopupChat}
            currentUser={currentUser}
            teamList={filteredData}
          />
        </div>

        <button className={`chat-btn-mobile ${isPopupChat ? 'open' : ''}`} type='button' onClick={сlickPopupChat}>Chat</button>
      </div>
    </>
  );
};

export default TeamPage;