import React from 'react';
import SignInForm from '../SignInForm/SignInForm';
import SignUpForm from '../SignUpForm/SignUpForm';

const SignForm = ({sign}) => {

  return (
    <>
      {sign === "in"
        ? <SignInForm/>
        : <SignUpForm/>
      }
    </>
  )
}

export default SignForm;
