import React from 'react';
import Loader from '../../Loader/Loader';

const HorizontalGraphic = ({ data, title, gridCol, classModificator, loader }) => {
  const arrayGraphic = data.map(item => {
    return (
      <div className="traffic__row" key={item.id}>
        <div className="traffic__text">
          <div className="traffic__name">{item.trafficName}</div>
          <div className="traffic__value">{item.trafficValue}</div>
        </div>
        <div className="traffic__line" style={{ backgroundColor: `${item.backgroundLine}` }}>
          <div className="traffic__line-value" style={{ backgroundColor: `${item.backgroungValue}`, width: `${item.trafficWidth}%` }}></div>
        </div>
      </div>
    )
  })

  return (
    <div className={`block-item ${gridCol} ${classModificator}`}>
      <div className="block-item__header">
        <h3 className="block-item__title">{title}</h3>
      </div>
      <div className="block-item__content">
        <div className="traffic-source">
          {loader ? (
            <Loader />
          )
            : (
              arrayGraphic
            )
          }
        </div>
      </div>
    </div>
  )
}

export default HorizontalGraphic;
