import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence  } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import {setUser} from '../../../store/slices/user';
import { useForm } from "react-hook-form";

const SignInForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignIn = async (data) => {
    try {
      await setPersistence(auth, browserLocalPersistence);

      await signInWithEmailAndPassword(auth, data.email, data.password)
        .then((result) => {
          dispatch(setUser({
            email: result.user.email,
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
          }));
        });

      navigate("/")
    } catch (error) {
      if(error.code === "auth/invalid-email") {
        setError('Please enter a valid email');
      } else if(error.code === "auth/wrong-password") {
        setError('Please enter the correct password');
      } else if(error.code === "auth/user-not-found") {
        setError('User does not exist');
      } else if(error.code === "auth/too-many-requests") {
        setError('Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
      }
    }
  }

  return (
    <>
      <form className='sign' onClick={handleSubmit(handleSignIn)}>
        <div className='sign_container'>
          <div className={`sign_item ${errors.email ? "error" : ''}`}>
            <label>Email address</label>
            <input
              name="email"
              type="text"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid."
                }
              })}
            />
            {errors.email &&
              <span className='error-message'>{errors.email.message}</span>
            }
          </div>
          <div className={`sign_item ${errors.password ? "error" : ''}`}>
            <label>Password</label>
            <div className='sign_item-input'>
              <input
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                {...register("password", {
                  required: "Password is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9]{6,12}$/,
                  },
                  minLength: {
                    value: 6,
                    message: "Password should be at-least 6 characters."
                  },
                  maxLength: {
                    value: 12,
                    message: "Password must be less than 12 characters"
                  },
                })}
              />
              <button
                className={`check-password ${passwordVisible ? 'show' : 'hide'}`}
                type="button"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.password && (
              <span className='error-message'>{errors.password.message}</span>
            )}
          </div>
          {error &&
            <div className='sign_item'>
              <span className='sign_item-error'>{error}</span>
            </div>
          }
          <div className='sign_forgot-password'>
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <div className='sign_action'>
            <button
              className='button button-primary'
              type="submit"
            >
              Sign In
            </button>
          </div>
        </div>
      </form>
      <div className='sign-form_action'>
        <span className='sign-form_action-text'>
          Don’t have an account yet?  <Link to="/sign-up" className='sign-form_action-link'>Sign Up</Link>
        </span>
      </div>
    </>
  )
}

export default SignInForm;
