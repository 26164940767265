import React from 'react';
import userNone from './user-none.png';
import { Link } from 'react-router-dom';

const CardTeam = ({img, status, name, position, email}) => {
  return (
    <div className='card-team'>
      <div className='card-team__settings'>
        <button className='card-team__settings-btn'></button>
        <div className='card-team__settings-content'>

        </div>
      </div>
      <div className='card-team__content'>
        <div className='card-team__field-image'>
          <img src={img === '' ? userNone : img} alt={name}/>
          <div className={status === 'online' ? 'user-status user-offline' : 'user-status user-online'}>
            {status}
          </div>
        </div>
        <div className='card-team__field-name'>
          <p>{name}</p>
        </div>
        <div className='card-team__field-position'>
          <p>{position}</p>
        </div>
        <div className='card-team__field-email'>
          <Link to={`mailto:${email}`}>Email</Link>
        </div>
      </div>
    </div>
  )
}

export default CardTeam;
