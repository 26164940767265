import React, { useState, useEffect } from 'react';
import { dataCurrency } from "../data/dataCurrency";
import { dataCrypto } from "../data/dataCrypto";
import LineGraphic from "../components/graphics/LineGraphic";
import BarGraphic from "../components/graphics/BarGraphic";
import HorizontalGraphic from '../components/graphics/HorizontalGraphic';
import LatestOrders from "../components/LatestOrders";
import Filter from "../components/Filter";
import CurrencyList from "../components/CurrencyList";
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, doc, getDoc } from 'firebase/firestore';

const HomePage = () => {
  const [dataTotalRevenue, setDataTotalRevenue] = useState([]);
  const [dataTotalOrders, setDataTotalOrders] = useState([]);
  const [dataAbandonedCarts, setDataAbandonedCarts] = useState([]);
  const [dataTotalVisitors, setDataTotalVisitors] = useState([]);
  const [dataTraffic, setDataTraffic] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);

  const [loadingRevenue, setLoadingRevenue] = useState(true);
  const [loadingTotalOrders, setLoadingTotalOrders] = useState(true);
  const [loadingAbandonedCarts, setLoadingAbandonedCarts] = useState(true);
  const [loadingTotalVisitors, setLoadingTotalVisitors] = useState(true);
  const [loadingTraffic, setLoadingTraffic] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const fetchDataFromFirestore = async () => {
    const ordersRef = collection(db, 'orders');
    const ordersListRef = doc(ordersRef, 'dataOrders');
    const ordersListDoc = await getDoc(ordersListRef);
    const ordersListData = ordersListDoc.data();

    const graphicsRef = collection(db, 'graphics');
    const graphicsListRef = doc(graphicsRef, 'dataGraphics');
    const graphicsListDoc = await getDoc(graphicsListRef);
    const graphicsListData = graphicsListDoc.data();

    setDataTotalRevenue(graphicsListData.dataTotalRevenue);
    setDataTotalOrders(graphicsListData.dataTotalOrders);
    setDataAbandonedCarts(graphicsListData.dataAbandonedCarts);
    setDataTotalVisitors(graphicsListData.dataTotalVisitors);
    setDataTraffic(graphicsListData.dataTraffic);

    setDataOrders(ordersListData.ordersList)

    setLoadingRevenue(false);
    setLoadingTotalOrders(false);
    setLoadingAbandonedCarts(false);
    setLoadingTotalVisitors(false);
    setLoadingTraffic(false);
    setLoadingOrders(false);
  };

  useEffect(() => {
    fetchDataFromFirestore();
  }, []);

  return (
    <>
      <div className="wrapper-blocks">
        <div className="data-blocks">
          <div className="blocks-list">
            <LineGraphic
              data={dataTotalRevenue}
              height={70}
              color='#3EBCBA'
              title='Total revenue'
              value={12246}
              percent={2.2}
              classPercent='block-item__percent_positive-arrow-up'
              gridCol='col-1'
              classModificator='block-item_total-revenue'
              loader={loadingRevenue}
            />
            <LineGraphic
              data={dataTotalOrders}
              height={70}
              color='#85A0FF'
              title='Total orders'
              value={1846}
              percent={1.2}
              classPercent='block-item__percent_negative'
              gridCol='col-1'
              classModificator='block-item_total-orders'
              loader={loadingTotalOrders}
            />
            <LineGraphic
              data={dataAbandonedCarts}
              height={70}
              color='#FF527B'
              title='Abandoned carts'
              value={112}
              percent={4.2}
              classPercent='block-item__percent_positive-arrow-down'
              gridCol='col-1'
              classModificator='block-item_abandoned-carts'
              loader={loadingAbandonedCarts}
            />
            <BarGraphic
              data={dataTotalVisitors}
              title='Total visitors'
              value={9124}
              gridCol='col-2'
              classModificator='block-item_total-visitors'
              loader={loadingTotalVisitors}
            />
            <HorizontalGraphic
              data={dataTraffic}
              title='Traffic Source'
              gridCol='col-1'
              classModificator='block-item_traffic-source'
              loader={loadingTraffic}
            />
            <LatestOrders
              dataOrders={dataOrders}
              title='Latest orders'
              gridCol='full-width'
              classModificator='block-item_latest-orders'
              loader={loadingOrders}
            />
          </div>
        </div>
        <div className="block-item currency-wrapper">
          <Filter />
          <div className="currency-block">
            <div className="block-item__header">
              <h3 className="block-item__title">Fiat</h3>
              <Link className="edit" to="#" >Edit</Link>
            </div>
            <CurrencyList data={dataCurrency} />
          </div>
          <div className="crypto-block">
            <div className="block-item__header">
              <h3 className="block-item__title">Crypto</h3>
              <Link className="edit" to="#">Edit</Link>
            </div>
            <CurrencyList data={dataCrypto} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage;
