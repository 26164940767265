import React, { useState, useEffect } from 'react';
import CurrencyItem from "../CurrencyItem";
import axios from 'axios';

const CurrencyList = ({data}) => {
  const [cryptoData, setCryptoData] = useState([]);
  const API_KEY = 'CG-f4aC5MQov3FwrTBEsnn3AcYH';

  const fetchCryptoData = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
        params: {
          ids: 'bitcoin,ethereum,tether,cardano,solana,ripple,usd',
          vs_currencies: 'usd,eur,cny,gbp,aud,jpy',
        },
        headers: {
          'Content-Type': 'application/json',
          'X-CoinGecko-Api-Key': API_KEY,
        },
      });

      const cryptoData = response.data;
      setCryptoData(cryptoData);
    } catch (error) {
      console.error('Error when retrieving data about cryptocurrencies', error);
    }
  };

  useEffect(() => {
    fetchCryptoData();
  }, []);

  const dataList = data.map(item => {
    const cryptoValue = cryptoData[item.id] ? cryptoData[item.id].usd : null;
    const fiatValue = cryptoData.usd && cryptoData.usd[item.id.toLowerCase()] ? cryptoData.usd[item.id.toLowerCase()] : null;

    return (
      <CurrencyItem item={item} key={item.id} cryptoValue={cryptoValue} fiatValue={fiatValue}/>
    );
  });

  return (
    <div className="currency__list">
      {dataList}
    </div>
  )
}

export default CurrencyList;
