import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { collection, setDoc, doc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import ReactSelectProduct from '../ReactSelectProduct/ReactSelectProduct';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Question from './question.jpg';
import userNone from './user-none.png';

const AddPopup = ({ isOpen, onClose, type, onAddProduct, onAddMember }) => {
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
  const [addingUser, setAddingUser] = useState(false);
  const [addingProduct, setAddingProduct] = useState(false);
  const [imageURL, setImageURL] = useState('');

  const onSubmitMember = async (data) => {
    const newMemberObj = {
      img: imageURL,
      email: data.email,
      name: data.fullName,
      position: data.position,
      status: 'offline',
    };

    try {
      setAddingUser(true);
      // const membersRef = collection(db, 'users');
      // const memberListRef = doc(membersRef, 'userListAll');
      // const memberListDoc = await getDoc(memberListRef);
      // const memberListData = memberListDoc.data();

      // memberListData.userList.push(newMemberObj);
      // await setDoc(memberListRef, memberListData);
      onAddMember(newMemberObj);
      onClose();
      reset();
    } catch (error) {
      console.error('Error adding user: ', error);
    } finally {
      setAddingUser(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  const handleImageMemberChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadMemberImage(file);
    }
  };

  const onSubmitProduct = async (data) => {
    const newProductObj = {
      id: data.productID,
      category: data.category.label,
      img: imageURL,
      name: data.productName,
      price: data.price,
      status: data.status.label,
    };

    try {
      setAddingProduct(true);
      // const productRef = collection(db, 'products');
      // const productListRef = doc(productRef, 'user-products');
      // const productListDoc = await getDoc(productListRef);
      // const productListData = productListDoc.data();

      // productListData.dataProducts.push(newProductObj);
      // await setDoc(productListRef, productListData);
      onAddProduct(newProductObj);
      onClose();
      reset();
    } catch (error) {
      console.error('Error adding user: ', error);
    } finally {
      setAddingProduct(false);
    }
  };

  const uploadImage = async (file) => {
    try {
      const storageRef = ref(storage, `Products/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(snapshot.ref);
      setImageURL(downloadURL);
    } catch (error) {
      console.error('Error uploading image: ', error);
    }
  };
  
  const uploadMemberImage = async (file) => {
    try {
      const storageRef = ref(storage, `Team/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(snapshot.ref);
      setImageURL(downloadURL);
    } catch (error) {
      console.error('Error uploading image: ', error);
    }
  };

  return (
    <div className={`add-popup ${isOpen ? 'open' : ''}`}>
      {type === 'member' &&
        <form onSubmit={handleSubmit(onSubmitMember)}>
          <button className='add-btn-close' onClick={onClose}>Close</button>
          <div className={`form_item ${errors.fullName ? "error" : ''}`}>
            <label>Full Name</label>
            <input
              name="fullName"
              type="text"
              {...register("fullName", {
                required: "Full name is required.",
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "First name is not valid."
                }
              })}
            />
            {errors.fullName &&
              <span className='error-message'>Full Name is required</span>
            }
          </div>
          <div className={`form_item ${errors.position ? "error" : ''}`}>
            <label>Position</label>
            <input
              name="position"
              type="text"
              {...register("position", {
                required: "Position is required.",
                pattern: {
                  value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                  message: "Position is not valid."
                }
              })}
            />
            {errors.position &&
              <span className='error-message'>Position is required</span>
            }
          </div>
          <div className={`form_item ${errors.email ? "error" : ''}`}>
            <label>Email address</label>
            <input
              name="email"
              type="text"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid."
                }
              })}
            />
            {errors.email &&
              <span className='error-message'>{errors.email.message}</span>
            }
          </div>
          <div className="form_item img member">
            <img
              src={imageURL ? imageURL : userNone}
              alt="Uploaded Member"
              className="uploaded-image"
            />
            <label className="input-file">
              <input type="file" name="file[]" multiple accept="image/*" onChange={handleImageMemberChange} />
              <span>Upload image</span>
            </label>
          </div>
          <button className='add-btn' disabled={addingUser} type="submit">{addingUser ? 'Adding...' : 'Add User'}</button>
        </form>
      }
      {type === 'product' &&
      <form onSubmit={handleSubmit(onSubmitProduct)}>
          <button className='add-btn-close' onClick={onClose}>Close</button>
          <div className={`form_item ${errors.productID ? "error" : ''}`}>
            <label>Product ID</label>
            <input
              name="productID"
              type="text"
              {...register("productID", {
                required: "Product ID is required.",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Product ID is not valid."
                }
              })}
            />
            {errors.productID &&
              <span className='error-message'>Product ID is required</span>
            }
          </div>
          <div className={`form_item ${errors.productName ? "error" : ''}`}>
            <label>Product name</label>
            <input
              name="productName"
              type="text"
              {...register("productName", {
                required: "Product name is required.",
                pattern: {
                  value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                  message: "Product name is not valid."
                }
              })}
            />
            {errors.productName &&
              <span className='error-message'>Product name is required</span>
            }
          </div>
          <div className={`form_item ${errors.category ? "error" : ''}`}>
            <label>Category</label>
            <ReactSelectProduct
              name="category"
              control={control}
              defaultValue=""
              rules={{ required: 'Category is required.' }}
              placeholder=""
              options={[
                { value: 'accessories', label: 'Accessories' },
                { value: 'handbag', label: 'Handbags&Purses' },
                { value: 'mclothes', label: 'Men’s Clothes' },
                { value: 'jewellery', label: 'Jewellery' },
                { value: 'wclothes', label: 'Women’s Clothes' },
                { value: 'mshoes', label: 'Men’s Shoes' },
                { value: 'wshoes', label: 'Women’s Shoes' },
              ]}
            />
            {errors.category &&
              <span className='error-message'>Category is required</span>
            }
          </div>
          <div className={`form_item ${errors.status ? "error" : ''}`}>
            <label>Status</label>
            <ReactSelectProduct
              name="status"
              control={control}
              defaultValue=""
              rules={{ required: 'Status is required.' }}
              placeholder=""
              options={[
                { value: 'instock', label: 'In stock' },
                { value: 'outstock', label: 'Out of stock' },
              ]}
            />
            {errors.status &&
              <span className='error-message'>Status is required</span>
            }
          </div>
          <div className={`form_item ${errors.price ? "error" : ''}`}>
            <label>Price</label>
            <input
              name="price"
              type="text"
              {...register("price", {
                required: "Price is required.",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Price is not valid."
                }
              })}
            />
            {errors.price &&
              <span className='error-message'>Price is required</span>
            }
          </div>
          <div className="form_item img">
            <img
              src={imageURL ? imageURL : Question}
              alt="Uploaded Product"
              className="uploaded-image"
            />
            <label className="input-file">
              <input type="file" name="file[]" multiple accept="image/*" onChange={handleImageChange} />
              <span>Upload image</span>
            </label>
          </div>
          <button className='add-btn' disabled={addingProduct} type="submit">{addingProduct ? 'Adding...' : 'Add Product'}</button>
        </form>
      }
    </div>
  );
};
export default AddPopup;
