import { createSlice } from '@reduxjs/toolkit';

const teamSlice = createSlice({
  name: 'teams',
  initialState: {
    data: [],
    loading: true,
    error: null,
  },
  reducers: {
    startLoading: (state) => {
      if (!state.data) {
        state.loading = true;
      }
    },
    loadSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    loadFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addTeamUser: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { startLoading, loadSuccess, loadFailure, addTeamUser } = teamSlice.actions;
export default teamSlice.reducer;