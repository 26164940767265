import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  email: null,
  displayName: null,
  photoURL: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
      state.photoURL = action.payload.photoURL;
    },
    removeUser(state) {
      state.email = null;
      state.displayName = null;
      state.photoURL = null;
    },
  },
})

export const {setUser, removeUser} = userSlice.actions;

export default userSlice.reducer;