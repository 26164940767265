import React from 'react';

const CurrencyItem = ({item, cryptoValue, fiatValue}) => {
  return (
    <div className="currency__item">
     {item.icon && <img className="currency__icon" src={item.icon} alt=""/>}
      <div className="currency__title">
        <div className="currency__name">{item.name}</div>
        <div className="currency__desc">{item.desc}</div>
      </div>
      <div className="currency__value">{item.type === 'crypto' && '$'}
          {fiatValue && <span>{fiatValue}</span>}
          {cryptoValue && <span>{cryptoValue}</span>}
        </div>
    </div>
  )
}

export default CurrencyItem;
