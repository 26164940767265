import React, { useState, useRef, useEffect } from 'react'
import userNone from './user-none.png';
import { auth } from '../../firebase';

const UserCurrent = ({user}) => {
  const [isActive, setIsActive] = useState(false);
  const profileRef = useRef(null);

  const сlickUser = () => {
    setIsActive(!isActive);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDocumentClick = (e) => {
    if (profileRef.current && !profileRef.current.contains(e.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div ref={profileRef} className={`user-current ${isActive ? 'active' : ''}`} onClick={сlickUser}>
      <img className='user-current__img' src={user.photoURL != null ? user.photoURL : userNone} alt='user-img'/>
      <span className='user-current__name'>{user.displayName}</span>
      <div className='user-current__profile'>
        <div className='user-current__content'>
          <span className='user-current__email'>{user.email}</span>
        </div>
        <button className='user-current__btn' onClick={handleLogout}>Log Out</button>
      </div>
    </div>
  )
}

export default UserCurrent;
