import React, { useState, useEffect, useRef } from 'react';
import userNone from './user-none.png';
import { db } from '../../firebase';
import { collection, getDocs, setDoc, doc, getDoc } from 'firebase/firestore';
import Loader from '../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  loadSuccess,
  loadFailure,
  addMessage as addMessageToRedux,
} from '../../store/slices/chat';

const Chat = ({onClick, currentUser, teamList}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loader, setLoader] = useState(true);
  const chatContainerRef = useRef(null);

  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chat.data);
  const loading = useSelector((state) => state.chat.loading);
  const error = useSelector((state) => state.chat.error);

  // const fetchDataFromFirestore = async () => {
  //   const querySnapshot = await getDocs(collection(db, 'messages'));
  //   const data = [];

  //   querySnapshot.forEach((doc) => {
  //     const messageData = doc.data().dataMessages;

  //     data.push(messageData)
  //   });

  //   setMessages(data);
  //   setLoader(false);
  // };

  // useEffect(() => {
  //   fetchDataFromFirestore();
  // }, []);

  const fetchDataFromFirestore = async () => {
    try {
      dispatch(startLoading());

      const querySnapshot = await getDocs(collection(db, 'messages'));
      const chatsData = [];

      querySnapshot.forEach((doc) => {
        const messageData = doc.data().dataMessages;
        messageData.forEach((chat) => {
          chatsData.push(chat);
        });
      });

      if (chatsData.length > 0) {
        dispatch(loadSuccess(chatsData));
      } else {
        dispatch(loadFailure("No data found."));
      }

      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    } catch (error) {
      dispatch(loadFailure(error.message));
    } finally {
      setLoader(false);
    }
  };
  
  useEffect(() => {
    if (!chats || chats.length === 0) {
      fetchDataFromFirestore();
    }
  }, [dispatch, chats]);

  const avatarMap = {};
  teamList.forEach((teamMember) => {
    avatarMap[teamMember.name] = teamMember.img;
  });

  const getCurrentTime = () => {
    const now = new Date();
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return now.toLocaleTimeString(undefined, options);
  };

  const handleSendMessage = async () => {
    const currentTime = getCurrentTime();
    const userName = currentUser.displayName;

    const newMessageObj = {
      user: userName,
      time: currentTime,
      message: newMessage,
      rtl: 'left',
    };

    try {
      // const messagesRef = collection(db, 'messages');
      // const messageListRef = doc(messagesRef, 'messageList');
      // const messageListDoc = await getDoc(messageListRef);
      // const messageListData = messageListDoc.data();

      // messageListData.dataMessages.push(newMessageObj);
      // await setDoc(messageListRef, messageListData);

      dispatch(addMessageToRedux(newMessageObj));
      setNewMessage('');
      // fetchDataFromFirestore();

    } catch (error) {
      console.error('Error adding message:', error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className='chat'>
      <div className='chat__title'>
        <h2>Team Chat</h2>
        <button className='chat__close' onClick={onClick}>Close</button>
      </div>
      {loading ? (
          <Loader />
        ) : (
          <div className='chat__content' ref={chatContainerRef}>
          {
            chats.map((message, index) => {
                if (currentUser && message.user === currentUser.displayName) {
                  return (
                    <div
                      key={index}
                      className={`chat-message chat-message-right`}
                    >
                      <div className='chat-message-header'>
                        <div className='chat-message-avatar'>
                          <img
                            className='chat-message-img'
                            src={
                              avatarMap[message.user] !== undefined || ''
                                ? userNone
                                : avatarMap[message.user] || currentUser.photoURL
                            }
                            alt={`${message.user}'s avatar`}
                          />
                        </div>
                        <span className='chat-message-user'>You</span>
                      </div>
                      <div className='chat-message-content'>
                        <div className='chat-message-time'>{message.time}</div>
                        <div className='chat-message-text'>{message.message}</div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={`chat-message chat-message-${message.rtl}`}
                    >
                      <div className='chat-message-header'>
                        <div className='chat-message-avatar'>
                          <img
                            className='chat-message-img'
                            src={
                              avatarMap[message.user] !== undefined || ''
                                ? avatarMap[message.user]
                                : userNone
                            }
                            alt={`${message.user}'s avatar`}
                          />
                        </div>
                        <span className='chat-message-user'>{message.user}</span>
                      </div>
                      <div className='chat-message-content'>
                        <div className='chat-message-time'>{message.time}</div>
                        <div className='chat-message-text'>{message.message}</div>
                      </div>
                    </div>
                  );
                }
            })
          }
          </div>
        )
      }
      <form className='chat__form'>
        <div className='chat__input'>
          <textarea
            type='text'
            placeholder='Type your message...'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            rows='3'
          />
          <div className='chat__actions'>
            <div className='chat__action'>
              <button className='chat__button download' type='button'>download</button>
            </div>
            <div className='chat__action'>
              <button className='chat__button mic' type='button'>mic</button>
            </div>
            <div className='chat__action'>
              <button onClick={handleFormSubmit} className='chat__button send' type='submit'>Send</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Chat;