import React from 'react';
import { Link } from 'react-router-dom';
import OrderRow from '../OrderRow';
import Loader from '../Loader/Loader';

const LatestOrders = ({ dataOrders, title, gridCol, classModificator, loader }) => {
  const allOrders = dataOrders.map(item => {
    return (
      <OrderRow key={item.id} item={item} />
    )
  })

  return (
    <div className={`block-item ${gridCol} ${classModificator}`}>
      <div className="block-item__header">
        <h3 className="block-item__title">{title}</h3>
        <Link className="view-all" to="#">View all</Link>
      </div>
      <div className="block-item__content">
        <div className="orders">
          <div className="orders__header">
            <div className="orders__row">
              <div className="orders__col orders__number">No</div>
              <div className="orders__col orders__id">ID</div>
              <div className="orders__col orders__date">Date</div>
              <div className="orders__col orders__price">Price</div>
              <div className="orders__status">Status</div>
            </div>
          </div>
          <div className="order-list">
            {loader ? (
              <Loader />
            )
              : (
                allOrders
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestOrders;
