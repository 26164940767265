import React from 'react';

const Filter = () => {
  return (
    <div className="filter">
      <h2 className="block-item__title">Exchange Rates</h2>
      <button className="btn-icon btn-filter"></button>
    </div>
  )
}

export default Filter;
