const dataCurrency = [
  {
    id: 'eur',
    name: 'USD-EUR',
    desc: 'Dollars to Euros',
    value: 0.89102,
  },
  {
    id: 'cny',
    name: 'USD-СNY',
    desc: 'Dollars to Yuan',
    value: 7.219,
  },
  {
    id: 'gbp',
    name: 'USD-GPB',
    desc: 'Dollars to Pounds',
    value: 0.77321,
  },
  {
    id: 'aud',
    name: 'USD-AUD',
    desc: 'Dollars to Australian Dollars',
    value: 1.47721,
  },
  {
    id: 'jpy',
    name: 'USD-JPY',
    desc: 'Dollars to Yen',
    value: 139.7527,
  },
]

export { dataCurrency };