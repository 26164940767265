import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AuthSideBar = () => {
  const location = useLocation();
  const [itemClasses, setItemClasses] = useState({});

  useEffect(() => {
    setItemClasses('');

    const currentPath = location.pathname;

    const timeout = setTimeout(() => {
      let newItemClasses = {};

      switch (currentPath) {
        case '/sign-up':
          newItemClasses = 'sign-up';
          break;
        case '/sign-in':
          newItemClasses = 'sign-in';
          break;
        case '/forgot-password':
          newItemClasses = 'sign-up';
          break;
        default:
          newItemClasses = '';
      }

      setItemClasses(newItemClasses);
    }, 200);

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <div className={`auth-sidebar ${itemClasses}`}>
      <div className='auth-sidebar__row'>
        <div className='auth-sidebar__item item-1'></div>
        <div className='auth-sidebar__item item-2'></div>
        <div className='auth-sidebar__item item-3'></div>
        <div className='auth-sidebar__item item-4'></div>
        <div className='auth-sidebar__item item-5'></div>
        <div className='auth-sidebar__item item-6'></div>
        <div className='auth-sidebar__item item-7'></div>
        <div className='auth-sidebar__item item-8'></div>
        <div className='auth-sidebar__item item-9'></div>
        <div className='auth-sidebar__item item-10'></div>
        <div className='auth-sidebar__item item-11'></div>
        <div className='auth-sidebar__item item-12'></div>
        <div className='auth-sidebar__item item-13'></div>
      </div>
    </div>
  )
}

export default AuthSideBar;
