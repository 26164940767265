import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const ReactSelectProduct = ({ name, control, defaultValue, rules, placeholder, options }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <Select
          {...field}
          inputId={name}
          placeholder={placeholder}
          options={options}
          classNamePrefix="react-select"
          className="select-container"
        />
      )}
    />
  );
}

export default ReactSelectProduct;