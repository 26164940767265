const dataCrypto = [
  {
    id: 'ethereum',
    icon: '/images/icons/icon-Etherium.svg',
    name: 'Ethereum',
    desc: 'ETH',
    value: 1908.77,
    type: 'crypto',
  },
  {
    id: 'bitcoin',
    icon: '/images/icons/icon-Bitcoin.svg',
    name: 'Bitcoin',
    desc: 'BTC',
    value: 29996.5,
    type: 'crypto',
  },
  {
    id: 'tether',
    icon: '/images/icons/icon-Tether.svg',
    name: 'Tether',
    desc: 'USDT',
    value: 1.00,
    type: 'crypto',
  },
  {
    id: 'cardano',
    icon: '/images/icons/icon-Cardano.svg',
    name: 'Cardano',
    desc: 'ADA',
    value: 0.79020,
    type: 'crypto',
  },
  {
    id: 'solana',
    icon: '/images/icons/icon-Solana.svg',
    name: 'Solana',
    desc: 'SOL',
    value: 26.210,
    type: 'crypto',
  },
  {
    id: 'ripple',
    icon: '/images/icons/icon-XRP.svg',
    name: 'XRP',
    desc: 'XRP',
    value: 0.79020,
    type: 'crypto',
  },
]

export { dataCrypto };