import React from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import Loader from '../../Loader/Loader';

const LineGraphic = ({data, height, color, title, value, percent, classPercent, gridCol, classModificator, loader}) => {

  const convertValue = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div className={`block-item ${gridCol} ${classModificator}`}>
      <div className="block-item__header">
        <h3 className="block-item__title">{title}</h3>
        <div className={`block-item__percent ${classPercent}`}><span>{percent}</span>%</div>
      </div>
      <div className="block-item__content">
        <div className="block-item__value">{classModificator === 'block-item_total-revenue' && '$'}<span>{convertValue(value)}</span></div>
        {loader ? (
            <Loader />
          )
          : (
            <ResponsiveContainer width="60%" height={height}>
              <LineChart width={300} height={900} data={data}>
                <Line type="monotone" dataKey="pv" stroke={color} strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          )
        }
      </div>
    </div>
  )
}

export default LineGraphic;
