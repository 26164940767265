import React from 'react';
import swiftdexImg from '../../../assets/icons/icon-swiftdex.svg';

const SignPreHeader = () => {
  return (
    <div className='sign-preheader'>
     <img className='sign-preheader__img' src={swiftdexImg} alt='swiftdex'/>
      <h4 className='sign-preheader__title'>SWIFTDEX</h4>
    </div>
  )
}

export default SignPreHeader;
