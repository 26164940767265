import React from 'react';
import {Puff} from 'react-loader-spinner';

const Loader = () => {
  return (
    <Puff
      height="50"
      width="50"
      radius={1}
      color="#BDCCFF"
      ariaLabel="puff-loading"
      wrapperClass="loader"
      visible={true}
    />
  )
}

export default Loader;
