import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import SignHeader from '../components/Sign/SignHeader/SignHeader';
import SignPreHeader from '../components/Sign/SignPreHeader/SignPreHeader';
import { Link } from 'react-router-dom';

const ForgotPassword = ({ title, description }) => {
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const auth = getAuth();
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const onSubmit = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      setResetEmailSent(true);
    } catch (error) {
      setError('email', { message: error.message });
    }
  };

  return (
    <div className='sign-container '>
      <SignPreHeader />
      {resetEmailSent ? (
        <div className='success-message'>
          <h2>Check your email for a password reset link.</h2>
          <div className='sign-form_action'>
            <span className='sign-form_action-text'>
              Already have an account?  <Link to="/sign-in" className='sign-form_action-link'>Sign In</Link>
            </span>
          </div>
        </div>
      ) : (
        <>
          <SignHeader
            title={title}
            description={description}
          />
          <div className='sign-form forgot-password'>
            <form className='sign' onSubmit={handleSubmit(onSubmit)}>
              <div className='sign_container'>
                <div className={`sign_item ${errors.email ? "error" : ''}`}>
                  <label>Email</label>
                  <input
                    name="email"
                    type="text"
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Email is not valid."
                      }
                    })}
                  />
                  {errors.email &&
                    <span className='error-message'>{errors.email.message}</span>
                  }
                </div>
                <div className='sign_action'>
                  <button
                    className='button button-primary'
                    type="submit"
                  >
                    Send link
                  </button>
                </div>
              </div>
            </form>
            <div className='sign-form_action'>
              <span className='sign-form_action-text'>
                Don’t have an account yet?  <Link to="/sign-up" className='sign-form_action-link'>Sign Up</Link>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ForgotPassword;
