import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import Loader from '../../Loader/Loader';

const BarGraphic = ({ data, title, value, gridCol, classModificator, loader }) => {
  const convertValue = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div className={`block-item ${gridCol} ${classModificator}`}>
      <div className="block-item__header">
        <h3 className="block-item__title">
          {title}
        </h3>
        <div className="block-item__value">{convertValue(value)}</div>
        <div className="maked-orders">Orders placed</div>
      </div>
      <div className="block-item__content">
        {loader ? (
          <Loader />
        )
          : (
            <ResponsiveContainer width="100%" height={160}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 10,
                  right: 0,
                  left: -15,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{ fontSize: '14px', fill: '#797D95', color: '#797D95', fontFamily: 'Onest' }} />
                <YAxis type="number" tick={{ fontSize: '14px', fill: '#797D95', color: '#797D95', fontFamily: 'Onest' }} domain={[0, 2000]} tickCount={5} interval={0} />
                <Bar dataKey="pv" fill="#C6C6C6" minPointSize={5} barSize={10} radius={[4, 4, 4, 4]} />
                <Bar dataKey="uv" fill="#85A0FF" minPointSize={10} barSize={10} radius={[4, 4, 4, 4]} />
              </BarChart>
            </ResponsiveContainer>
          )
        }
      </div>
    </div>
  )
}

export default BarGraphic;
