import React, { useState, useEffect } from 'react';
import LineGraphic from "../components/graphics/LineGraphic";
import LatestOrders from "../components/LatestOrders";
import { db } from '../firebase';
import { collection, doc, getDoc } from 'firebase/firestore';

const OrdersPage = () => {
  const [dataTotalOrders, setDataTotalOrders] = useState([]);
  const [dataAbandonedCarts, setDataAbandonedCarts] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);

  const [loadingTotalOrders, setLoadingTotalOrders] = useState(true);
  const [loadingAbandonedCarts, setLoadingAbandonedCarts] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const fetchDataFromFirestore = async () => {
    const ordersRef = collection(db, 'orders');
    const ordersListRef = doc(ordersRef, 'dataOrders');
    const ordersListDoc = await getDoc(ordersListRef);
    const ordersListData = ordersListDoc.data();

    const graphicsRef = collection(db, 'graphics');
    const graphicsListRef = doc(graphicsRef, 'dataGraphics');
    const graphicsListDoc = await getDoc(graphicsListRef);
    const graphicsListData = graphicsListDoc.data();

    setDataTotalOrders(graphicsListData.dataTotalOrders);
    setDataAbandonedCarts(graphicsListData.dataAbandonedCarts);

    setDataOrders(ordersListData.ordersList);

    setLoadingTotalOrders(false);
    setLoadingAbandonedCarts(false);
    setLoadingOrders(false);
  };

  useEffect(() => {
    fetchDataFromFirestore();
  }, []);
  return (
    <>
      <div className="wrapper-orders-content">
      <div className="wrapper-blocks">
        <div className="data-blocks">
          <div className="blocks-list">
            <LineGraphic
              data={dataTotalOrders}
              height={70}
              color='#85A0FF'
              title='Total orders'
              value={1846}
              percent={1.2}
              classPercent='block-item__percent_negative'
              gridCol='col-50'
              classModificator='block-item_total-orders'
              loader={loadingTotalOrders}
            />
            <LineGraphic
              data={dataAbandonedCarts}
              height={70}
              color='#FF527B'
              title='Abandoned carts'
              value={112}
              percent={4.2}
              classPercent='block-item__percent_positive-arrow-down'
              gridCol='col-50'
              classModificator='block-item_abandoned-carts'
              loader={loadingAbandonedCarts}
            />
            <LatestOrders
              dataOrders={dataOrders}
              title='Latest orders'
              gridCol='full-width'
              classModificator='block-item_latest-orders'
              loader={loadingOrders}
            />
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default OrdersPage;
