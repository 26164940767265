import React from 'react';

const OrderRow = ({ item }) => {
  let classStatus = '';
  switch (item.statusOrder) {
    case 'New':
      classStatus = 'orders__status_new';
      break;
    case 'Delivery':
      classStatus = 'orders__status_delivery';
      break;
    case 'On hold':
      classStatus = 'orders__status_on-hold';
      break;
    default:
      break;
  }

  const convertValue = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div className="orders__row">
      <div className="orders__col orders__number">
        <span className="order-label">No </span>{item.numberOrder}
      </div>
      <div className="orders__col orders__id">
        <span className="order-label">ID </span>#{item.id}
      </div>
      <div className="orders__col orders__date">{item.dateOrder}</div>
      <div className="orders__col orders__price">
        <span className="order-label">Price </span>
        $<span>{convertValue(item.price)}</span>
      </div>
      <div className={`orders__status ${classStatus}`}>{item.statusOrder}</div>
    </div>
  )
}

export default OrderRow;
