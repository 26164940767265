import React from 'react';
import SignHeader from '../components/Sign/SignHeader/SignHeader';
import SignBtn from '../components/Sign/SignBtn/SignBtn';
import SignForm from '../components/Sign/SignForm/SignForm';
import { GoogleAuthProvider } from 'firebase/auth';
import SignPreHeader from '../components/Sign/SignPreHeader/SignPreHeader';

const SignUp = ({ title, description }) => {
  const providerGoogle = new GoogleAuthProvider();

  return (
    <div className='sign-container'>
      <SignPreHeader/>
      <SignHeader
        title={title}
        description={description}
      />
      <div className='sign-buttons'>
        <SignBtn
          link="#"
          social="google"
          type="Sign up with Google"
          provider={providerGoogle}
        />
      </div>
      <div className='sign-form'>
        <SignForm sign="up" />
      </div>
    </div>
  )
}

export default SignUp;
