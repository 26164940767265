import React from 'react'
import { NavLink } from "react-router-dom";

const NavMenu = () => {
  return (
    <nav className="nav">
      <ul>
        <li>
          <NavLink className="nav__item nav__item_dashboard" to="/">Dashboard</NavLink>
        </li>
        <li>
          <NavLink className="nav__item nav__item_orders" to="/orders">Orders</NavLink>
        </li>
        <li>
          <NavLink className="nav__item nav__item_products" to="products">Products</NavLink>
        </li>
        <li>
          <NavLink className="nav__item nav__item_team" to="/team">Team</NavLink>
        </li>
        <li>
          <NavLink className="nav__item nav__item_settings" to="/settings">Settings</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default NavMenu;
