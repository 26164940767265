import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import userNone from './user-none.png';
import { auth, storage  } from '../../firebase';
import { updatePassword, updateProfile, updateEmail } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const SettingsCard = ({ title, description, user }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      displayedName: user.displayName,
      fullName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  });

  const password = useRef();
  password.current = watch('password', '');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordConfirmVisibility = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  const handleActivateForm = () => {
    setIsFormActive(true);
  };

  const handleCancelChanges = () => {
    reset();
    setIsFormActive(false);
  };
  
  const handleCancelGeneralChanges = () => {
    reset();
    setActiveField(null);
  };

  const activateField = (fieldName) => {
    setActiveField(fieldName);
  };

  const changeFirebasePassword = async (newPassword) => {
    try {
      const user = auth.currentUser;

      if (user) {
        await updatePassword(user, newPassword);
        console.log('Password changed successfully');
      } else {
        console.log('The user is not authenticated');
      }
    } catch (error) {
      console.error('Password change error:', error);
    }
  };

  const handleFormSubmit = async (data) => {
    if (data.password === data.confirmPassword) {
      await changeFirebasePassword(data.password);
      reset();
      setIsFormActive(false);
    } else {
      console.error('Passwords do not match');
    }
  };

  const handleFormGeneralSubmit = async (data) => {
    if (data.displayedName !== user.displayName) {
      try {
        await updateProfile(auth.currentUser, {
          displayName: data.displayedName,
        });
        console.log('Display Name changed successfully');
      } catch (error) {
        console.error('Error changing Display Name:', error);
      }
    }

    if (data.fullName !== user.displayName) {
      try {
        await updateProfile(auth.currentUser, {
          displayName: data.displayedName,
        });
        console.log('Display Name changed successfully');
      } catch (error) {
        console.error('Error changing Display Name:', error);
      }
    }
  
    if (data.email !== user.email) {
      try {
        await updateEmail(auth.currentUser, data.email);
        console.log('Email changed successfully');
      } catch (error) {
        console.error('Error changing Email:', error);
      }
    }

    reset();
    setActiveField(null);
  };

  const updateImage = async (file) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const storageRef = ref(storage, `user-images/${currentUser.uid}/${file.name}`);

      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        await updateProfile(currentUser, { photoURL: downloadURL });
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      updateImage(file);
    }
  };

  return (
    <div className='settings-card'>
      <div className='settings-card__header'>
        <h3 className='settings-card__title'>{title}</h3>
        <div className='settings-card__description'>
          <p>{description}</p>
        </div>
      </div>
      <div className='settings-card__content'>
        {title === 'General' &&
          <form className='settings-form' onSubmit={handleSubmit(handleFormGeneralSubmit)} >
            <div className='settings-form__container'>
              <div className='settings-form__row col-2'>
                <div className='settings-form__item'>
                  <img className='settings-form__img'
                    src={user.photoURL != null || '' ? user.photoURL : userNone}
                    alt='user-img' />
                  <label className="input-file">
                    <input type="file" name="file[]" multiple accept="image/*" onChange={handleImageChange}/>
                    <span>Update image</span>
                  </label>
                </div>
              </div>
              <div className='settings-form__row col-10'>
                <div className={`settings-form__item ${errors.displayedName ? "error" : ''}`}>
                  <label className='settings-form__label'>Displayed Name</label>
                  <div className='settings-form-input'>
                    <input
                      className='settings-form__input'
                      name="displayedName"
                      type="text"
                      {...register("displayedName", {
                        required: "Displayed Name is required.",
                        pattern: {
                          value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                          message: "Displayed Name is not valid."
                        }
                      })}
                      disabled={activeField !== 'displayedName'}
                    />
                    {activeField !== 'displayedName' && (
                      <button
                        className='button-edit'
                        type='button'
                        onClick={() => activateField('displayedName')}
                      >
                        Edit
                      </button>
                    )}
                    {errors.displayedName &&
                      <span className='error-message'>{errors.displayedName.message}</span>
                    }
                  </div>
                </div>
                <div className={`settings-form__item ${errors.fullName ? "error" : ''}`}>
                  <label className='settings-form__label'>Full Name</label>
                  <div className='settings-form-input'>
                    <input
                      className='settings-form__input'
                      name="fullName"
                      type="text"
                      {...register("fullName", {
                        required: "Full name is required.",
                        pattern: {
                          value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                          message: "Full name is not valid."
                        }
                      })}
                      disabled={activeField !== 'fullName'}
                    />
                    {activeField !== 'fullName' && (
                      <button
                        className='button-edit'
                        type='button'
                        onClick={() => activateField('fullName')}
                      >
                        Edit
                      </button>
                    )}
                    {errors.fullName &&
                      <span className='error-message'>{errors.fullName.message}</span>
                    }
                  </div>
                </div>
                <div className={`settings-form__item ${errors.position ? "error" : ''}`}>
                  <label className='settings-form__label'>Position</label>
                  <div className='settings-form-input'>
                    <input className='settings-form__input'
                      name="position"
                      type="text"
                      {...register("position", {
                        pattern: {
                          value: /^[a-zA-Z\s\p{L}\p{M}]+$/,
                          message: "Position is not valid."
                        }
                      })}
                      disabled={activeField !== 'position'}
                    />
                    {activeField !== 'position' && (
                      <button
                        className='button-edit'
                        type='button'
                        onClick={() => activateField('position')}
                      >
                        Edit
                      </button>
                    )}
                    {errors.position &&
                      <span className='error-message'>{errors.position.message}</span>
                    }
                  </div>
                </div>
                <div className={`settings-form__item ${errors.email ? "error" : ''}`}>
                  <label className='settings-form__label'>Email</label>
                  <div className='settings-form-input'>
                    <input
                      className='settings-form__input'
                      name="email"
                      type="text"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          message: "Email is not valid."
                        }
                      })}
                      disabled={activeField !== 'email'}
                    />
                    {activeField !== 'email' && (
                      <button
                        className='button-edit'
                        type='button'
                        onClick={() => activateField('email')}
                      >
                        Edit
                      </button>
                    )}
                    {errors.email &&
                      <span className='error-message'>{errors.email.message}</span>
                    }
                  </div>
                </div>
                <div className={`settings-form__item ${errors.phoneNumber ? "error" : ''}`}>
                  <label className='settings-form__label'>Phone number</label>
                  <div className='settings-form-input'>
                    <input className='settings-form__input'
                      disabled={activeField !== 'phoneNumber'}
                      name="phoneNumber"
                      type="text"
                      placeholder='+5-555-555-5555'
                      {...register("phoneNumber", {
                        pattern: {
                          value: /^\+\d{1,3}-\d{3}-\d{3}-\d{4}$/,
                          message: "Phone number is not valid."
                        }
                      })}
                    />
                    {activeField !== 'phoneNumber' && (
                      <button
                        className='button-edit'
                        type='button'
                        onClick={() => activateField('phoneNumber')}
                      >
                        Edit
                      </button>
                    )}
                    {errors.phoneNumber &&
                      <span className='error-message'>{errors.phoneNumber.message}</span>
                    }
                  </div>
                </div>
                {activeField && (
                  <div className='settings-form__action'>
                    <button className='button button-primary' type='submit'>
                      Save
                    </button>
                    <button className='button button-secondary' onClick={handleCancelGeneralChanges}>
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        }
        {title === 'Login info' && (
          <form className='settings-form' onSubmit={handleSubmit(handleFormSubmit)}>
            <div className='settings-form__container'>
              <div className='settings-form__row col-10 offset-2'>
                <div className='settings-form__item'>
                  <label className='settings-form__label'>Username (Email)</label>
                  <input
                    className='settings-form__input'
                    name="email"
                    type="text"
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Email is not valid."
                      }
                    })}
                    disabled
                  />
                </div>
                <div className={`settings-form__item ${errors.password ? "error" : ''}`}>
                  <label className='settings-form__label'>New password</label>
                  <div className='settings-form-input'>
                    <input
                      className='settings-form__input'
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      {...register("password", {
                        required: "Password is required.",
                        pattern: {
                          value: /^[a-zA-Z0-9]{6,12}$/,
                          message: "Password must contain only uppercase and lowercase letters and numbers"
                        },
                        minLength: {
                          value: 6,
                          message: "Password should be at least 6 characters."
                        },
                        maxLength: {
                          value: 12,
                          message: "Password must be less than 12 characters."
                        },
                      })}
                      disabled={!isFormActive}
                    />
                    <button
                      className={`check-password ${passwordVisible ? 'show' : 'hide'}`}
                      type="button"
                      onClick={togglePasswordVisibility}
                    />
                    {errors.password && (
                      <span className='error-message'>{errors.password.message}</span>
                    )}
                  </div>
                </div>
                {isFormActive &&
                  <div className={`settings-form__item ${errors.confirmPassword ? "error" : ''}`}>
                    <label className='settings-form__label'>Submit password</label>
                    <div className='settings-form-input'>
                      <input
                        className='settings-form__input'
                        name="confirmPassword"
                        type={passwordConfirmVisible ? 'text' : 'password'}
                        {...register("confirmPassword", {
                          required: "Confirm password is required.",
                          validate: (value) => value === password.current || 'Passwords do not match'
                        })}
                      />
                      <button
                        className={`check-password ${passwordConfirmVisible ? 'show' : 'hide'}`}
                        type="button"
                        onClick={togglePasswordConfirmVisibility}
                      />
                      {errors.confirmPassword &&
                        <span className='error-message'>{errors.confirmPassword.message}</span>
                      }
                    </div>
                  </div>
                }
                <div className='settings-form__action'>
                  {isFormActive ? (
                    <>
                      <button className='button button-primary' type='submit'>
                        Change password
                      </button>
                      <button className='button button-secondary' onClick={handleCancelChanges}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button className='button button-primary' onClick={handleActivateForm}>
                      Change password
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default SettingsCard;
